import * as actionTypes from "./actionTypes";
import {
  getIssueLicencesApi,
  deleteIssueLicenceAPI,
  createIssueLicenceApi,
  updateIssueLicenceApi,
  importIssueLicencesExcelApi,
  downloadIssueLicencesExcelApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { downloadBlob } from "@icarius-utils/download";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar la licencia porque está en uso';
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existen incidencias dentro de las fechas ingresadas, la licencia no puede grabarse';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getIssuesLicencesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getIssueLicencesApi();

    let data = response.data && response.data.data;
    let selects = response.data && response.data.selects;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, selects },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteIssuesLicenceAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteIssueLicenceAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Licencia eliminada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createIssuesLicenceAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createIssueLicenceApi(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Licencia creada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateIssuesLicenceAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateIssueLicenceApi(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Licencia actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadStructureAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadIssueLicencesExcelApi({ type: "LICENCES", empty: false });
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importSpreadsheetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);

    let response = await importIssueLicencesExcelApi(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};