import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateHHMM, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';
import moment from "moment";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidTimePerDayCallback, selects, presetEmployee) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName.includes('Fecha')) {
        if (data?.[fieldName]) return createDateFromDDMMYYYY(data[fieldName]);
        if (['Fecha desde', 'Fecha desde de aplicación'].includes(fieldName)) return new Date();
        return null;
      }

      if (fieldName === 'Tiempo por día') {
        if (isCreate) return null;
        const auxTimePerDay = data[fieldName];
        if (auxTimePerDay.includes(':')) {
          const auxDate = new Date();
          const [hours, minutes] = auxTimePerDay.split(':');
          auxDate.setHours(hours, minutes, 0, 0);
          return auxDate;
        }
        return auxTimePerDay;
      }

      if (fieldName === 'Código de empleado') {
        if(presetEmployee) return selects.employees.find(item => item.key === presetEmployee);
        return isCreate ? null : selects.employees.find(item => item.key === data[fieldName]);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Código de empleado',
      'Tipo de inasistencia',
      'Fecha desde',
      'Fecha hasta',
      'Fecha desde de aplicación',
      'Fecha hasta de aplicación',
      'Unidad diaria',
      'Tiempo por día',
      'Comentarios',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const timePerDayIsValid = () => {
    if (!isCreate) return true;
    // si no hay tiempo por dia
    if (!formData['Tiempo por día']) {
      invalidDataCallback();
      return false;
    }

    // si hay tiempo por dia y la unidad es DIA, es valido
    if (formData['Unidad diaria'] === "D") return true;

    // si hay tiempo por dia, la unidad es HORA, validar si se pasa de las horas del empleado
    if (formData['Unidad diaria'] === "H" && formData['Código de empleado']?.maxHours) {
      const [maxHours, maxMinutes] = formData['Código de empleado'].maxHours.split(':');
      const maxTimePerDayDate = new Date(formData['Tiempo por día'] || new Date());
      maxTimePerDayDate.setHours(maxHours, maxMinutes, 0, 0);

      // hago copia para setear en 0 los segundos y que no me cague la comparación
      const auxTimePerDay = new Date(formData['Tiempo por día']);
      auxTimePerDay.setSeconds(0);
      auxTimePerDay.setMilliseconds(0);

      // si NO se pasa de las horas
      if (auxTimePerDay <= maxTimePerDayDate) return true;

      // si se pasa de las horas del empleado
      invalidTimePerDayCallback();
      return false;
    }

    // en caso de que el empleado no tenga horas maximas (error de db)
    invalidTimePerDayCallback();
    return false;
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Código de empleado': yup.object().required(),
      'Tipo de inasistencia': yup.string().required(),
      'Fecha desde': yup.date().required(),
      'Fecha hasta': yup.date().required(),
      'Fecha desde de aplicación': yup.date().required(),
      'Fecha hasta de aplicación': yup.date(),
      'Unidad diaria': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (timePerDayIsValid()) {
        if (isCreate) {
          const dataToSend = {
            ...formData,
            'Código de empleado': formData['Código de empleado'].key,
            'Fecha desde': formatDateYYYYMMDD(formData['Fecha desde']),
            'Fecha hasta': formatDateYYYYMMDD(formData['Fecha hasta']),
            'Fecha desde de aplicación': formatDateYYYYMMDD(formData['Fecha desde de aplicación']),
            'Fecha hasta de aplicación': formatDateYYYYMMDD(formData['Fecha hasta de aplicación']),
            'Tiempo por día': formData['Unidad diaria'] === 'H' ? formatDateHHMM(formData['Tiempo por día']) : formData['Tiempo por día'],
          };

          createCallback(dataToSend);
          return true;
        }

        modifyCallback({ 'Comentarios': formData['Comentarios'], internalCode: data.internalCode });
        return true;
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const calculateEndApplicationDate = (initialDate, startDate, endDate) => {
    if (initialDate && startDate && endDate) {
      const daysToAdd = moment(endDate).diff(moment(startDate), 'days');
      return moment(initialDate, "DD/MM/YYYY").add(daysToAdd, 'days').toDate();
    }

    return null;
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'Tipo de inasistencia') {
      const newUnit = selects.absenceTypes.find(item => item.key === value)?.unit;
      let newTimePerDay = formData['Tiempo por día'];
      if (newUnit !== formData['Unidad diaria']) { // si cambia Unidad diaria, limpiar Tiempo por día
        newTimePerDay = newUnit === 'H' ? null : '';
      }

      setFormData({
        ...formData,
        [fieldName]: value,
        'Unidad diaria': newUnit,
        'Tiempo por día': newTimePerDay,
      });
      return;
    }

    const altersEndDate = ['Fecha desde', 'Fecha hasta', 'Fecha desde de aplicación'];
    if (altersEndDate.includes(fieldName)) {
      let dateFrom = formData['Fecha desde'];
      let dateTo = formData['Fecha hasta'];
      let appDateFrom = formData['Fecha desde de aplicación'];
      let appDateTo;

      if (fieldName === 'Fecha desde') {
        dateFrom = value;
        dateTo = value; //siempre pisar la fecha hasta cuando se cambia la fecha desde
      }

      if (fieldName === 'Fecha hasta') {
        dateTo = value;
      }

      if (fieldName === 'Fecha desde de aplicación') {
        appDateFrom = value;
      }

      if (fieldName === 'Fecha desde') {
        appDateFrom = dateFrom; // si cambia fecha desde, fecha desde de aplicacion va a tener el mismo valor
        appDateTo = dateTo; // si fecha desde y fecha desde de aplicacion son iguales, la fecha hasta tambien
      } else {
        appDateTo = calculateEndApplicationDate(appDateFrom, dateFrom, dateTo);
      }

      setFormData({
        ...formData,
        [fieldName]: value,
        'Fecha hasta': dateTo,
        'Fecha desde de aplicación': appDateFrom,
        'Fecha hasta de aplicación': appDateTo,
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
