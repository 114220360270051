import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Código de empleado',
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Apellido y nombre',
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Tipo de inasistencia',
            field: "Tipo de inasistencia string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Fecha desde',
            field: "Fecha desde",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Cantidad de días',
            field: "Cantidad de días",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Fecha hasta',
            field: "Fecha hasta",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Fecha desde de aplicación',
            field: "Fecha desde de aplicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Fecha hasta de aplicación',
            field: "Fecha hasta de aplicación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Unidad diaria',
            field: "Unidad diaria string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Tiempo por día',
            field: "Tiempo por día string",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.issueAbsences],
        config: {
            headerName: 'Comentarios',
            field: "Comentarios",
            filter: "agTextColumnFilter",
        }
    },
]