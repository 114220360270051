import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { createIssuesLicenceAction, updateIssuesLicenceAction } from "@icarius-pages/issueLicences/actions";
import { getSelects } from "@icarius-pages/issueLicences/selectors";
import { Autocomplete } from "@material-ui/lab";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, loading, data, presetEmployee, handleClose } = props;

  const dispatch = useDispatch();
  const selects = useSelector(getSelects);
  const { classes, employees, licenceTypes, units, timePerDayTypes } = selects;

  const create = (dataToSend) => {
    dispatch(createIssuesLicenceAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateIssuesLicenceAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openTimePerDayValidationError = () => {
    dispatch(openSnackbarAction({ msg: `El tiempo por día no puede superar las ${formData['Código de empleado'].maxHours} horas`, severity: "error", duration: 10000 }));
  }

  const getMaxHoursLabel = () => {
    if (!formData['Código de empleado']) return "";
    return `Máximo permitido: ${formData['Código de empleado'].maxHours}`;
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError, openTimePerDayValidationError, selects, presetEmployee);

  const filteredEmployees = useMemo(() => {
    if(!employees) return [];
    if(presetEmployee) return employees.filter(item => item.key === presetEmployee);
    return employees;
  }, [employees, presetEmployee])

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear licencia médica" : "Editar licencia médica"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <Autocomplete
                disabled={!isCreate}
                style={{ width: '100%' }}
                options={filteredEmployees}
                value={formData['Código de empleado']}
                onChange={(event, newUser) => {
                  setFormValue(newUser, 'Código de empleado');
                }}
                noOptionsText={''}
                getOptionLabel={(user) => user.value}
                renderInput={(params) => <TextField required {...params} label={'Empleado'} />}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de licencia"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData['Tipo de licencia']}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de licencia')}
                  margin={"none"}
                >
                  {
                    licenceTypes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}> {/* IPC */}
              <TextField
                disabled={!isCreate}
                label={'Código de licencia'}
                fullWidth
                margin={"none"}
                value={formData['Código de licencia']}
                onChange={(e) => setFormValue(e.target.value, "Código de licencia")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  fullWidth
                  required
                  label={'Fecha desde'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha desde']}
                  onChange={(e) => setFormValue(e, 'Fecha desde')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}> {/* IPC */}
              <TextField
                disabled={!isCreate}
                required
                label={'Cantidad de días'}
                type='number'
                fullWidth
                margin={"none"}
                value={formData['Cantidad de días']}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(0);
                    setFormValue(e.target.value, 'Cantidad de días');
                  } else {
                    setFormValue(e.target.value, '')
                  }
                }}
                inputProps={{ min: "1", step: "1", max: "365" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha hasta'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha hasta']}
                  onChange={(e) => setFormValue(e, 'Fecha hasta')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  fullWidth
                  required
                  label={'Fecha desde de aplicación'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha desde de aplicación']}
                  onChange={(e) => setFormValue(e, 'Fecha desde de aplicación')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha hasta de aplicación'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha hasta de aplicación']}
                  onChange={(e) => setFormValue(e, 'Fecha hasta de aplicación')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-unit`}>{"Unidad diaria"}</InputLabel>
                <Select
                  disabled
                  value={formData['Unidad diaria']}
                  labelId={`label-unit`}
                  id={`select-unit`}
                  onChange={(e) => setFormValue(e.target.value, 'Unidad diaria')}
                  margin={"none"}
                >
                  {
                    units.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              {
                formData['Unidad diaria'] === "D" ?
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label-timeperday`}>{"Tiempo por día"}</InputLabel>
                    <Select
                      disabled={!isCreate}
                      value={formData['Tiempo por día']}
                      labelId={`label-timeperday`}
                      id={`select-timeperday`}
                      onChange={(e) => setFormValue(e.target.value, 'Tiempo por día')}
                      margin={"none"}
                    >
                      {
                        timePerDayTypes.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  :
                  <div style={{ width: '100%', marginBottom: formData['Código de empleado'] ? '-24px' : 0 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <TimePicker
                        disabled={!Boolean(formData['Unidad diaria']) || !isCreate}
                        fullWidth
                        required
                        minutesStep={5}
                        label={getLocalizedString("timePerDay")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        invalidDateMessage=""
                        value={formData['Tiempo por día']}
                        onChange={(e) => setFormValue(e, 'Tiempo por día')}
                        format="HH:mm"
                        margin="none"
                        ampm={false}
                        helperText={getMaxHoursLabel()}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
              }
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-class`}>{"Clase"}</InputLabel>
                <Select
                  disabled
                  value={formData['Clase']}
                  labelId={`label-class`}
                  id={`select-class`}
                  onChange={(e) => setFormValue(e.target.value, 'Clase')}
                  margin={"none"}
                >
                  {
                    classes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  disabled
                  label={'Fecha de retorno'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha de retorno']}
                  onChange={(e) => setFormValue(e, 'Fecha de retorno')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* IPC */}
              <TextField
                label={'Comentarios'}
                fullWidth
                margin={"none"}
                value={formData['Comentarios']}
                onChange={(e) => setFormValue(e.target.value, "Comentarios")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
