import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  downloadStructureAction,
  getIssueAbsencesAction,
  deleteIssueAbsenceAction,
} from "../actions";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { getUserData } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useIssueAbsences = (groupCode) => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const userData = useSelector(getUserData);

  useEffect(() => {
    dispatch(getIssueAbsencesAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (userData.level === "M" && documents[0]['Código de empleado'] === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede eliminar sus propias inasistencias', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar la inasistencia?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (userData.level === "M" && documents[0]['Código de empleado'] === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede editar sus propias inasistencias', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDelete = (document) => {
    const dataToSend = {
      ...document,
      'Fecha desde': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha desde'])),
      'Fecha hasta': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha hasta'])),
      'Fecha desde de aplicación': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha desde de aplicación'])),
      'Fecha hasta de aplicación': formatDateYYYYMMDD(createDateFromDDMMYYYY(document['Fecha hasta de aplicación'])),
    };
    dispatch(deleteIssueAbsenceAction(dataToSend));
  }

  const handleDownloadStructure = () => {
    dispatch(downloadStructureAction());
  }

  const handleOpenUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(false);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    uploadFileDialogIsOpen,
  }

  const handlers = {
    handleDelete,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDownloadStructure,
    handleOpenUploadFileDialogIsOpen,
    handleCloseUploadFileDialogIsOpen,
  }

  return {
    data,
    isLoading,
    state,
    handlers
  }
}

export default useIssueAbsences;