import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateHHMM, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';
import moment from "moment";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidTimePerDayCallback, selects, presetEmployee) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName.includes('Fecha')) {
        if (data?.[fieldName]) return createDateFromDDMMYYYY(data[fieldName]);
        if (['Fecha desde', 'Fecha desde de aplicación'].includes(fieldName)) return new Date();
        return null;
      }

      if (fieldName === 'Tiempo por día') {
        if (isCreate) return null;
        const auxTimePerDay = data[fieldName];
        if (auxTimePerDay.includes(':')) {
          const auxDate = new Date();
          const [hours, minutes] = auxTimePerDay.split(':');
          auxDate.setHours(hours, minutes, 0, 0);
          return auxDate;
        }
        return auxTimePerDay;
      }

      if (fieldName === 'Código de empleado') {
        if(presetEmployee) return selects.employees.find(item => item.key === presetEmployee);
        return isCreate ? null : selects.employees.find(item => item.key === data[fieldName]);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Código de empleado',
      'Tipo de licencia',
      'Fecha desde',
      'Cantidad de días',
      'Fecha hasta',
      'Fecha desde de aplicación',
      'Fecha hasta de aplicación',
      'Unidad diaria',
      'Tiempo por día',
      'Clase',
      'Fecha de retorno',
      'Código de licencia',
      'Comentarios',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const timePerDayIsValid = () => {
    if (!isCreate) return true;
    // si no hay tiempo por dia
    if (!formData['Tiempo por día']){
      invalidDataCallback();
      return false;
    }

    // si hay tiempo por dia y la unidad es DIA, es valido
    if (formData['Unidad diaria'] === "D") return true;

    // si hay tiempo por dia, la unidad es HORA, validar si se pasa de las horas del empleado
    if (formData['Unidad diaria'] === "H" && formData['Código de empleado']?.maxHours) {
      const [maxHours, maxMinutes] = formData['Código de empleado'].maxHours.split(':');
      const maxTimePerDayDate = new Date(formData['Tiempo por día'] || new Date());
      maxTimePerDayDate.setHours(maxHours, maxMinutes, 0, 0);

      // hago copia para setear en 0 los segundos y que no me cague la comparación
      const auxTimePerDay = new Date(formData['Tiempo por día']);
      auxTimePerDay.setSeconds(0);
      auxTimePerDay.setMilliseconds(0);

      // si NO se pasa de las horas
      if(auxTimePerDay <= maxTimePerDayDate) return true;

      // si se pasa de las horas del empleado
      invalidTimePerDayCallback();
      return false;
    }

    // en caso de que el empleado no tenga horas maximas (error de db)
    invalidTimePerDayCallback();
    return false;
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Código de empleado': yup.object().required(),
      'Tipo de licencia': yup.string().required(),
      'Fecha desde': yup.date().required(),
      'Cantidad de días': yup.string().required(),
      'Fecha hasta': yup.date(),
      'Fecha desde de aplicación': yup.date().required(),
      'Fecha hasta de aplicación': yup.date(),
      'Unidad diaria': yup.string().required(),
      'Clase': yup.string().required(),
      'Fecha de retorno': yup.date().nullable(true),
      'Código de licencia': yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (timePerDayIsValid()) {
        if (isCreate) {
          const dataToSend = {
            ...formData,
            'Código de empleado': formData['Código de empleado'].key,
            'Fecha desde': formatDateYYYYMMDD(formData['Fecha desde']),
            'Fecha hasta': formatDateYYYYMMDD(formData['Fecha hasta']),
            'Fecha desde de aplicación': formatDateYYYYMMDD(formData['Fecha desde de aplicación']),
            'Fecha hasta de aplicación': formatDateYYYYMMDD(formData['Fecha hasta de aplicación']),
            'Fecha de retorno': formatDateYYYYMMDD(formData['Fecha de retorno']),
            'Tiempo por día': formData['Unidad diaria'] === 'H' ? formatDateHHMM(formData['Tiempo por día']) : formData['Tiempo por día'],
          };

          createCallback(dataToSend);
          return true;
        }

        modifyCallback({ 'Comentarios': formData['Comentarios'], internalCode: data.internalCode });
        return true;
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "Cantidad de días" && value > 365) {
      return 365;
    }

    return value;
  }

  const calculateEndDate = (initialDate, employee, licenceTypeCode, amountOfDays) => {
    if (initialDate && employee && licenceTypeCode && amountOfDays) {
      const licenceType = selects.licenceTypes.find(item => item.key === licenceTypeCode);

      if (employee.includeSaturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }

      if (!licenceType.includeHolidaysAndWeekends) {
        moment.updateLocale('es', {
          holidays: employee.holidays,
          holidayFormat: 'DD/MM/YYYY'
        });

        let initialDay = moment(initialDate, "DD/MM/YYYY");
        // Tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
        if (!initialDay.isBusinessDay()) {
          initialDay = initialDay.nextBusinessDay();
        }

        return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
      } else {
        return moment(initialDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();
      }
    }

    return null;
  }

  const calculateEndApplicationDate = (initialDate, startDate, endDate) => {
    if (initialDate && startDate && endDate) {
      const daysToAdd = moment(endDate).diff(moment(startDate), 'days');
      return moment(initialDate, "DD/MM/YYYY").add(daysToAdd, 'days').toDate();
    }

    return null;
  }

  const calculateReturnDate = (initialDate, employee) => {
    if (initialDate && employee) {
      if (employee.includeSaturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }

      moment.updateLocale('es', {
        holidays: employee.holidays,
        holidayFormat: 'DD/MM/YYYY'
      });

      let initialDay = moment(initialDate, "DD/MM/YYYY");
      
      // Tengo que avanzar hasta el proximo dia habil
      initialDay = initialDay.nextBusinessDay();

      return initialDay.toDate();
    }

    return null;
  }

  const setFormValue = (value, fieldName) => {
    // si cambia alguno de los que afecta al calculo de fecha final, recalcular la fecha hasta y fecha hasta de aplicación
    const altersEndDate = ['Tipo de licencia', 'Código de empleado', 'Cantidad de días', 'Fecha desde', 'Fecha desde de aplicación'];
    if (altersEndDate.includes(fieldName)) {
      let employee = formData['Código de empleado'];
      let licenceTypeCode = formData['Tipo de licencia'];
      let amountOfDays = formData['Cantidad de días'];
      let newUnit = formData['Unidad diaria'];
      let newTimePerDay = formData['Tiempo por día'];
      let dateFrom = formData['Fecha desde'];
      let appDateFrom = formData['Fecha desde de aplicación'];
      let appDateTo;
      let newClass = formData['Clase'];

      if (fieldName === 'Tipo de licencia') {
        licenceTypeCode = value;
        const licenceType = selects.licenceTypes.find(item => item.key === value);
        newUnit = licenceType?.unit;
        newClass = licenceType?.classType;
        if (newUnit !== formData['Unidad diaria']) { // si cambia Unidad diaria, limpiar Tiempo por día
          newTimePerDay = newUnit === 'H' ? null : '';
        }
      }

      if (fieldName === 'Código de empleado') {
        employee = value;
      }

      if (fieldName === 'Cantidad de días') {
        amountOfDays = value;
      }

      if (fieldName === 'Fecha desde') {
        dateFrom = value;
      }

      if (fieldName === 'Fecha desde de aplicación') {
        appDateFrom = value;
      }

      const dateTo = calculateEndDate(dateFrom, employee, licenceTypeCode, amountOfDays);
      if (fieldName === 'Fecha desde') {
        appDateFrom = dateFrom; // si cambia fecha desde, fecha desde de aplicacion va a tener el mismo valor
        appDateTo = dateTo; // si fecha desde y fecha desde de aplicacion son iguales, la fecha hasta tambien
      } else {
        appDateTo = calculateEndApplicationDate(appDateFrom, dateFrom, dateTo);
      }

      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        'Clase': newClass,
        'Unidad diaria': newUnit,
        'Tiempo por día': newTimePerDay,
        'Fecha hasta': dateTo,
        'Fecha desde de aplicación': appDateFrom,
        'Fecha hasta de aplicación': appDateTo,
        'Fecha de retorno': calculateReturnDate(dateTo, employee),
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
